<template>
  <router-view v-if="show && location"/>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment-timezone';

@Component({
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
  watch: {
    location: {
      immediate: true,
      handler (newVal, oldVal) {
        if (!newVal) return;
        if (typeof oldVal === 'undefined' || ((!!oldVal && !!newVal) && oldVal.id !== newVal.id)) {
          this.show = false;
          this.initLocation();
          this.$nextTick().then(() => {
            this.show = true;
          });
        }
      },
    },
  },
})
export default class Location extends Vue {
  show = false;

  get location () {
    return this.$store.getters['entities/Location'](this.locationId);
  }

  async initLocation () {
    await this.$store.dispatch('getLocationDetails', this.locationId);
    moment.tz.setDefault(this.location.timezone);
  }
}
</script>
